<template>
	<div>
		<Breadcrumb titulo="CHATBOT" />
	</div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
import ChatConfigServices from '@/assets/js/services/ChatConfigServices'

export default {
    name: 'ChatTestePage',

    components: {
        Breadcrumb
    },

    data: function() {
        return {
            chatKey: '',
        }
    },
    
    async created() {
		this.chatKey = await ChatConfigServices.getChatKey();
		document.body.append(Object.assign(document.createElement("script"), {
			id: "sd-chatbot",
			src: `${this.$hostOmni}/lib/chatbot/root.js`
		}))
		$("#sd-chatbot").attr("data-hash", this.chatKey);
    }
}
</script>

<style scoped>
    
</style>